import { useRouter } from 'next/router'
import { forwardRef, ReactNode, useCallback, useMemo } from 'react'

import { Button, ButtonProps, Flex, FlexProps, Text } from '@nexpy/design-system'

type MenuItemProps = {
  label: string
  icon: (params: { isActive: boolean }) => ReactNode
  path: string
  mode?: 'push' | 'replace'
  events?: {
    routeChangeStart?: () => void
    onFinally?: () => void
    onCatch?: () => void
    onThen?: (data: boolean) => void
    onClick?: () => void
  }
  pseudo?: {
    containerProps?: FlexProps
    iconFlexProps?: FlexProps
    labelFlexProps?: FlexProps
  }
} & Omit<ButtonProps, 'variant' | 'type' | 'onClick'>

const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ label, icon, path, mode, events, pseudo, ...props }: MenuItemProps, ref) => {
    const router = useRouter()

    const handleClick = useCallback(() => {
      if (events?.routeChangeStart) {
        router.events.on('routeChangeStart', events.routeChangeStart)
      }

      if (events?.onClick) {
        events.onClick()

        return
      }

      if (mode === 'push') {
        router
          .push(path)
          .then(events?.onThen)
          .catch(events?.onCatch)
          .finally(events?.onFinally)

        return
      }

      router
        .replace(path)
        .then(events?.onThen)
        .catch(events?.onCatch)
        .finally(events?.onFinally)
    }, [events, mode, path, router])

    const isActive = useMemo(
      () => router.pathname.startsWith(path),
      [path, router.pathname]
    )

    return (
      <Button
        w='100%'
        display='flex'
        alignItems='center'
        {...props}
        ref={ref}
        variant='ghost'
        type='button'
        onClick={handleClick}
      >
        <Flex alignItems='center' gap='1rem' {...pseudo?.containerProps} w='100%'>
          <Flex
            w='3rem'
            justifyContent='center'
            alignItems='center'
            {...pseudo?.iconFlexProps}
          >
            {icon({ isActive })}
          </Flex>
          <Flex alignItems='center' {...pseudo?.labelFlexProps}>
            <Text
              textAlign='left'
              fontWeight={isActive ? '600' : '400'}
              display='flex'
              alignItems='center'
            >
              {label}
            </Text>
          </Flex>
        </Flex>
      </Button>
    )
  }
)

MenuItem.defaultProps = {
  mode: 'push',
  events: undefined,
  pseudo: undefined,
}

MenuItem.displayName = 'MenuItem'

export default MenuItem
