import { Box } from '@nexpy/design-system'
import styled from '@xstyled/styled-components'

export const ContainerBackground = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: systemBlack;
`

export const Container = styled(Box)`
  background-color: white;

  width: 30rem;
  max-width: 30rem;
  min-width: 30rem;

  padding: 3rem;

  border-radius: 0.4rem 0 0 0.4rem;

  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 2.4rem;
  overflow-y: auto;

  transition: right 0.25s ease;
`
