import { Box } from '@nexpy/design-system'
import styled from '@xstyled/styled-components'

import { TOPBAR_HEIGHT } from 'constants/layout'

export const ContainerBackground = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: systemBlack;
`

export const Container = styled(Box)`
  background-color: white;

  width: 30rem;
  max-width: 30rem;
  min-width: 30rem;

  padding: 3rem;

  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

  border-radius: 0.4rem 0 0 0.4rem;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 2.4rem;
  overflow-y: auto;

  z-index: 10;

  transition: right 0.25s ease;

  @media (min-width: md) {
    box-shadow: none;

    position: fixed;
    top: ${TOPBAR_HEIGHT};
    bottom: 0;
    right: unset !important;

    border-right-color: platinum;
    border-right-style: solid;
    border-right-width: 1px;
    border-radius: 0;

    z-index: 5;
  }
`
