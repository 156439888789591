import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import { Box } from '@nexpy/design-system'
import { NotificationsRoutes } from 'routes/notifications'

import Footer from 'components/generics/Footer'
import MobileNavBar from 'components/generics/MobileNavBar'
import TopBar from 'components/generics/TopBar'

import { MainRefContextContext } from 'contexts/MainRefContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

type DefaultTopBarProps = {
  children: ReactNode
}

const DefaultTopBar = ({ children }: DefaultTopBarProps) => {
  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  const router = useRouter()

  return (
    <>
      <TopBar />

      <Box h={TOPBAR_HEIGHT} w='100%' />

      <Box
        ref={mainRef}
        as='main'
        p={{ _: '2.4rem', md: '3rem' }}
        maxHeight={`calc(100vh - ${TOPBAR_HEIGHT})`}
        overflowY='auto'
        w='100%'
      >
        {children}

        <MobileNavBar />

        <Footer
          display={
            [NotificationsRoutes.ROOT].some(route => router.pathname === route)
              ? 'none'
              : 'flex'
          }
        />
      </Box>
    </>
  )
}

export default DefaultTopBar
