import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type CatalogFillProps = {
  color?: string
} & BoxProps

const CatalogFill = ({ color, ...props }: CatalogFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.66675 40.3335C4.29175 40.3335 3.11425 39.9415 2.13425 39.1575C1.15425 38.3735 0.665086 37.4322 0.666752 36.3335V4.3335C0.666752 3.2335 1.15675 2.2915 2.13675 1.5075C3.11675 0.723499 4.29342 0.332166 5.66675 0.333499H35.6667C37.0417 0.333499 38.2192 0.7255 39.1992 1.5095C40.1792 2.2935 40.6684 3.23483 40.6667 4.3335V36.3335C40.6667 37.4335 40.1767 38.3755 39.1967 39.1595C38.2167 39.9435 37.0401 40.3348 35.6667 40.3335H5.66675Z'
        fill={color}
      />
      <path d='M24 20L29 16.7857L34 20V5H24V20Z' fill='#F9F5FF' />
    </svg>
  </Box>
)

CatalogFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default CatalogFill
