import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type CalendarProps = {
  color?: string
} & BoxProps

const Calendar = ({ color, ...props }: CalendarProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.88889 20H13.3333V24H8.88889V20ZM40 8V36C40 38.22 38.0222 40 35.5556 40H4.44444C3.2657 40 2.13524 39.5786 1.30175 38.8284C0.468252 38.0783 0 37.0609 0 36V8C0 5.8 2 4 4.44444 4H6.66667V0H11.1111V4H28.8889V0H33.3333V4H35.5556C36.7343 4 37.8648 4.42143 38.6983 5.17157C39.5317 5.92172 40 6.93913 40 8ZM4.44444 12H35.5556V8H4.44444V12ZM35.5556 36V16H4.44444V36H35.5556ZM26.6667 24V20H31.1111V24H26.6667ZM17.7778 24V20H22.2222V24H17.7778ZM8.88889 28H13.3333V32H8.88889V28ZM26.6667 32V28H31.1111V32H26.6667ZM17.7778 32V28H22.2222V32H17.7778Z'
        fill={color}
      />
    </svg>
  </Box>
)

Calendar.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Calendar
