import { ReactNode } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Box, Flex } from '@nexpy/design-system'

import CompanyFullPageLoader from 'components/common/CompanyFullPageLoader'
import ConsoleNavBar from 'components/generics/ConsoleNavBar'
import ConsoleSettingsMenuButton from 'components/generics/ConsoleSettingsMenuButton'
import Container from 'components/generics/Container'
import TopBar from 'components/generics/TopBar'

import Menu from 'views/console/settings/components/Menu'

import { ConsoleSettingsMenuContext } from 'contexts/ConsoleSettingsMenuContext'
import { MainRefContextContext } from 'contexts/MainRefContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

const Settings = ({ children }: { children: ReactNode }) => {
  const setIsOpened = ConsoleSettingsMenuContext.useSelector(state => state.setIsOpened)

  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  const swipeMenuOpenHandlers = useSwipeable({
    onSwipedLeft: () => setIsOpened(true),
    delta: 13,
  })

  return (
    <CompanyFullPageLoader>
      <TopBar topLeftHomeOnMobile />

      <Box h={TOPBAR_HEIGHT} w='100%' />

      <Flex position='relative' {...swipeMenuOpenHandlers}>
        <ConsoleNavBar />
        <Box
          ref={mainRef}
          as='main'
          maxHeight={`calc(100vh - ${TOPBAR_HEIGHT})`}
          overflowY='auto'
          w='100%'
        >
          <Flex justifyContent='space-between'>
            <Menu />

            <Box w='100%' p={{ _: '2.4rem', md: '3rem' }}>
              <ConsoleSettingsMenuButton />

              <Container>
                {children}

                <Box h='6rem' display={{ md: 'none' }} />
              </Container>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </CompanyFullPageLoader>
  )
}

export default Settings
