import { memo, ReactNode } from 'react'

import { RedirectToSignIn, SignedIn, SignedOut, useAuth } from '@clerk/nextjs'

import AuthFullPageLoader from 'components/common/AuthFullPageLoader'
import ErrorBoundary from 'components/common/ErrorBoundary'
import AccountManage from 'components/templates/AccountManage'
import ConsoleLayout from 'components/templates/ConsoleLayout'
import Default from 'components/templates/Default'
import DefaultTopBar from 'components/templates/DefaultTopBar'
import DefaultTopBarMinimalist from 'components/templates/DefaultTopBarMinimalist'
import DefaultTopBarWithoutFooter from 'components/templates/DefaultTopBarWithoutFooter'
import Settings from 'components/templates/Settings'

type PageConfig = {
  auth: boolean
  Component: any
  ui?:
    | 'default-top-bar'
    | 'default-top-bar-without-footer'
    | 'console-layout'
    | 'console-settings'
    | 'default-top-bar-minimalist'
    | 'account-manage'
    | 'default'
}

type LayoutProps = {
  children: ReactNode
}

export const page = ({ auth, Component, ui }: PageConfig) => {
  const Layout = ({ children }: LayoutProps) => {
    switch (ui) {
      case 'default-top-bar':
        return <DefaultTopBar>{children}</DefaultTopBar>

      case 'default-top-bar-without-footer':
        return <DefaultTopBarWithoutFooter>{children}</DefaultTopBarWithoutFooter>

      case 'console-layout':
        return <ConsoleLayout>{children}</ConsoleLayout>

      case 'console-settings':
        return <Settings>{children}</Settings>

      case 'default-top-bar-minimalist':
        return <DefaultTopBarMinimalist>{children}</DefaultTopBarMinimalist>

      case 'account-manage':
        return <AccountManage>{children}</AccountManage>

      case 'default':
        return <Default>{children}</Default>

      default:
        return <>{children}</>
    }
  }

  const PageWrapper = (pageProps: any) => {
    const { isSignedIn } = useAuth()

    return (
      <ErrorBoundary errorBoundaryId='page-level' feedbackUi='error' useCapture>
        <AuthFullPageLoader auth={auth} canRender={Boolean(isSignedIn)}>
          <Layout>
            {auth === false ? (
              <Component {...pageProps} />
            ) : (
              <>
                <SignedIn>
                  <Component {...pageProps} />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            )}
          </Layout>
        </AuthFullPageLoader>
      </ErrorBoundary>
    )
  }

  return memo(PageWrapper)
}
