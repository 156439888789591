import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { Box, Button, Flex } from '@nexpy/design-system'
import { HomeRoutes } from 'routes/home'
import { MySchedulesRoutes } from 'routes/my-schedules'
import { SearchRoutes } from 'routes/search'

// import Chat from 'components/icons/Chat'
// import ChatFill from 'components/icons/ChatFill'
import Hair from 'components/icons/Hair'
import HairFill from 'components/icons/HairFill'
import Home from 'components/icons/Home'
import HomeFill from 'components/icons/HomeFill'
import SearchFill from 'components/icons/SearchFill'

import { customTheme } from 'theme/theme'

const MdOutlineSearch = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineSearch)
)

type MobileNavBarIconProps = {
  isActive: boolean
}

const HomeIcon = ({ isActive }: MobileNavBarIconProps) => {
  if (isActive) {
    return <HomeFill w='2.45rem' />
  }

  return <Home w='2.45rem' />
}

// const ChatIcon = ({ isActive }: MobileNavBarIconProps) => {
//   if (isActive) {
//     return <ChatFill w='2.6rem' />
//   }

//   return <Chat w='2.6rem' />
// }

const SearchIcon = ({ isActive }: MobileNavBarIconProps) => {
  if (isActive) {
    return <SearchFill w='2.35rem' />
  }

  return <MdOutlineSearch size='3rem' color={customTheme.colors.dodger} />
}

const HairIcon = ({ isActive }: MobileNavBarIconProps) => {
  if (isActive) {
    return <HairFill w='2.6rem' />
  }

  return <Hair w='2.6rem' />
}

const MobileNavBar = () => {
  const router = useRouter()

  const handleGoHome = useCallback(() => router.push(HomeRoutes.ROOT), [router])

  // const handleGoToChat = useCallback(() => router.push(ChatRoutes.ROOT), [router])

  const handleGoToSearch = useCallback(() => router.push(SearchRoutes.ROOT), [router])

  const handleGoToMySchedules = useCallback(
    () => router.push(MySchedulesRoutes.ROOT),
    [router]
  )

  return (
    <>
      <Flex
        h='6rem'
        position='fixed'
        zIndex='5'
        bottom='0'
        right='0'
        left='0'
        display={{ md: 'none' }}
        borderTopColor='gainsboro'
        borderTopStyle='solid'
        borderTopWidth='1px'
        justifyContent='space-evenly'
        alignItems='center'
        bg='white'
      >
        <Button
          variant='ghost'
          aria-label='Página inicial'
          onClick={handleGoHome}
          w='2.8rem'
        >
          <HomeIcon isActive={router.pathname === '/'} />
        </Button>

        <Button
          variant='ghost'
          aria-label='Pesquisar'
          display={{ lg: 'none' }}
          onClick={handleGoToSearch}
          w='2.8rem'
        >
          <SearchIcon isActive={router.pathname.startsWith(SearchRoutes.ROOT)} />
        </Button>

        {/* <SignedIn>
          <Button variant='ghost' aria-label='Chat' onClick={handleGoToChat} w='2.8rem'>
            <ChatIcon isActive={router.pathname.startsWith(ChatRoutes.ROOT)} />
          </Button>
        </SignedIn> */}

        <Button
          variant='ghost'
          aria-label='Meus Agendamentos'
          onClick={handleGoToMySchedules}
          w='2.8rem'
        >
          <HairIcon isActive={router.pathname.startsWith(MySchedulesRoutes.ROOT)} />
        </Button>
      </Flex>

      <Box h='6rem' display={{ md: 'none' }} />
    </>
  )
}

export default MobileNavBar
