import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type CalendarFillProps = {
  color?: string
} & BoxProps

const CalendarFill = ({ color, ...props }: CalendarFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40 36V8C40 5.794 38.0067 4 35.5556 4H31.1111V0H26.6667V4H13.3333V0H8.88889V4H4.44444C1.99333 4 0 5.794 0 8V36C0 38.206 1.99333 40 4.44444 40H35.5556C38.0067 40 40 38.206 40 36ZM13.3333 32H8.88889V28H13.3333V32ZM13.3333 24H8.88889V20H13.3333V24ZM22.2222 32H17.7778V28H22.2222V32ZM22.2222 24H17.7778V20H22.2222V24ZM31.1111 32H26.6667V28H31.1111V32ZM31.1111 24H26.6667V20H31.1111V24ZM35.5556 12H20H4.44444V8H20H35.5556V12Z'
        fill={color}
      />
    </svg>
  </Box>
)

CalendarFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default CalendarFill
