import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type ReportsFillProps = {
  color?: string
} & BoxProps

const ReportsFill = ({ color, ...props }: ReportsFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 6.66667C0 4.89856 0.702379 3.20286 1.95262 1.95262C3.20286 0.702379 4.89856 0 6.66667 0H33.3333C35.1014 0 36.7971 0.702379 38.0474 1.95262C39.2976 3.20286 40 4.89856 40 6.66667V33.3333C40 35.1014 39.2976 36.7971 38.0474 38.0474C36.7971 39.2976 35.1014 40 33.3333 40H6.66667C4.89856 40 3.20286 39.2976 1.95262 38.0474C0.702379 36.7971 0 35.1014 0 33.3333V6.66667ZM22.2222 11.1111C22.2222 10.5217 21.9881 9.95651 21.5713 9.53976C21.1546 9.12302 20.5894 8.88889 20 8.88889C19.4106 8.88889 18.8454 9.12302 18.4287 9.53976C18.0119 9.95651 17.7778 10.5217 17.7778 11.1111V28.8889C17.7778 29.4783 18.0119 30.0435 18.4287 30.4602C18.8454 30.877 19.4106 31.1111 20 31.1111C20.5894 31.1111 21.1546 30.877 21.5713 30.4602C21.9881 30.0435 22.2222 29.4783 22.2222 28.8889V11.1111ZM13.3333 17.7778C13.3333 17.1884 13.0992 16.6232 12.6825 16.2064C12.2657 15.7897 11.7005 15.5556 11.1111 15.5556C10.5217 15.5556 9.95651 15.7897 9.53976 16.2064C9.12302 16.6232 8.88889 17.1884 8.88889 17.7778V28.8889C8.88889 29.4783 9.12302 30.0435 9.53976 30.4602C9.95651 30.877 10.5217 31.1111 11.1111 31.1111C11.7005 31.1111 12.2657 30.877 12.6825 30.4602C13.0992 30.0435 13.3333 29.4783 13.3333 28.8889V17.7778ZM31.1111 24.4444C31.1111 23.8551 30.877 23.2898 30.4602 22.8731C30.0435 22.4564 29.4783 22.2222 28.8889 22.2222C28.2995 22.2222 27.7343 22.4564 27.3175 22.8731C26.9008 23.2898 26.6667 23.8551 26.6667 24.4444V28.8889C26.6667 29.4783 26.9008 30.0435 27.3175 30.4602C27.7343 30.877 28.2995 31.1111 28.8889 31.1111C29.4783 31.1111 30.0435 30.877 30.4602 30.4602C30.877 30.0435 31.1111 29.4783 31.1111 28.8889V24.4444Z'
        fill={color}
      />
    </svg>
  </Box>
)

ReportsFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default ReportsFill
