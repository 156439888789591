import { ReactNode } from 'react'

import { Box, Flex } from '@nexpy/design-system'

import CompanyFullPageLoader from 'components/common/CompanyFullPageLoader'
import ConsoleNavBar from 'components/generics/ConsoleNavBar/ConsoleNavBar'
import TopBar from 'components/generics/TopBar'

import { MainRefContextContext } from 'contexts/MainRefContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

type ConsoleLayoutProps = {
  children: ReactNode
}

const ConsoleLayout = ({ children }: ConsoleLayoutProps) => {
  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  return (
    <CompanyFullPageLoader>
      <TopBar topLeftHomeOnMobile />

      <Box h={TOPBAR_HEIGHT} w='100%' />

      <Flex position='relative'>
        <ConsoleNavBar />
        <Box
          ref={mainRef}
          as='main'
          maxHeight={`calc(100vh - ${TOPBAR_HEIGHT})`}
          overflowY='auto'
          w='100%'
          p={{ _: '2.4rem', md: '3rem' }}
        >
          {children}

          <Box h='6rem' display={{ md: 'none' }} />
        </Box>
      </Flex>
    </CompanyFullPageLoader>
  )
}

export default ConsoleLayout
