import dynamic from 'next/dynamic'
import { useCallback } from 'react'

import { Box, Button, Flex } from '@nexpy/design-system'

import { ConsoleSettingsMenuContext } from 'contexts/ConsoleSettingsMenuContext'

import { customTheme } from 'theme/theme'

const MdMenu = dynamic(() => import('react-icons/md').then(mod => mod.MdMenu))

const ConsoleSettingsMenuButton = () => {
  const setIsOpened = ConsoleSettingsMenuContext.useSelector(state => state.setIsOpened)

  const handleManuOpen = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  return (
    <Flex justifyContent='flex-end' alignItems='center' w='100%' display={{ md: 'none' }}>
      <Button
        borderRadius='50%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        variant='ghost'
        onClick={handleManuOpen}
        aria-label='Abrir menu das configurações da empresa'
        position='relative'
        zIndex='5'
      >
        <Box display={{ sm: 'none' }} mr='-0.95rem'>
          <MdMenu color={customTheme.colors.black} size='4rem' />
        </Box>
        <Box display={{ _: 'none', sm: 'initial' }} mr='-0.35rem'>
          <MdMenu color={customTheme.colors.black} size='3rem' />
        </Box>
      </Button>
    </Flex>
  )
}

export default ConsoleSettingsMenuButton
