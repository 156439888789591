import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type ClientsProps = {
  color?: string
} & BoxProps

const Clients = ({ color, ...props }: ClientsProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 42 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.6749 0.800169L37.6749 0.8H37.6667H21H4.33333V0.799815L4.32475 0.800184C2.37806 0.883846 0.869182 2.69278 0.800098 4.89744L0.8 4.89744V4.9037V32.2296H0.799902L0.800098 32.2359C0.869182 34.4406 2.37806 36.2495 4.32475 36.3331L4.32474 36.3333H4.33333H37.6667V36.3335L37.6753 36.3331C39.6219 36.2495 41.1308 34.4406 41.1999 32.2359L41.2 32.2359V32.2296V4.9037H41.2001L41.1999 4.89771C41.168 3.83196 40.7923 2.81509 40.1418 2.05331C39.4906 1.29073 38.61 0.838595 37.6749 0.800169ZM4.53333 32.0296V5.1037H37.4667V32.0296H4.53333Z'
        fill={color}
        stroke={color}
        strokeWidth='0.4'
      />
      <path
        d='M22 26V24.125C22 21.635 16.99 20.375 14.5 20.375C12.01 20.375 7 21.635 7 24.125V26H22ZM14.5 11C13.5054 11 12.5516 11.3951 11.8483 12.0983C11.1451 12.8016 10.75 13.7554 10.75 14.75C10.75 15.2425 10.847 15.7301 11.0355 16.1851C11.2239 16.64 11.5001 17.0534 11.8483 17.4017C12.5516 18.1049 13.5054 18.5 14.5 18.5C14.9925 18.5 15.4801 18.403 15.9351 18.2145C16.39 18.0261 16.8034 17.7499 17.1517 17.4017C17.4999 17.0534 17.7761 16.64 17.9646 16.1851C18.153 15.7301 18.25 15.2425 18.25 14.75C18.25 14.2575 18.153 13.7699 17.9646 13.3149C17.7761 12.86 17.4999 12.4466 17.1517 12.0983C16.8034 11.7501 16.39 11.4739 15.9351 11.2855C15.4801 11.097 14.9925 11 14.5 11Z'
        fill={color}
      />
      <path
        d='M22 26V24.125C22 21.635 16.99 20.375 14.5 20.375C12.01 20.375 7 21.635 7 24.125V26H22ZM14.5 11C13.5054 11 12.5516 11.3951 11.8483 12.0983C11.1451 12.8016 10.75 13.7554 10.75 14.75C10.75 15.2425 10.847 15.7301 11.0355 16.1851C11.2239 16.64 11.5001 17.0534 11.8483 17.4017C12.5516 18.1049 13.5054 18.5 14.5 18.5C14.9925 18.5 15.4801 18.403 15.9351 18.2145C16.39 18.0261 16.8034 17.7499 17.1517 17.4017C17.4999 17.0534 17.7761 16.64 17.9646 16.1851C18.153 15.7301 18.25 15.2425 18.25 14.75C18.25 14.2575 18.153 13.7699 17.9646 13.3149C17.7761 12.86 17.4999 12.4466 17.1517 12.0983C16.8034 11.7501 16.39 11.4739 15.9351 11.2855C15.4801 11.097 14.9925 11 14.5 11Z'
        stroke={color}
      />
      <rect x='23' y='11' width='12' height='2' rx='0.4' fill={color} />
      <rect x='23' y='16' width='12' height='2' rx='0.4' fill={color} />
      <rect x='25' y='21' width='10' height='2' rx='0.4' fill={color} />
    </svg>
  </Box>
)

Clients.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Clients
