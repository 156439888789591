import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type CatalogProps = {
  color?: string
} & BoxProps

const Catalog = ({ color, ...props }: CatalogProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 36.003V36V4C2 3.59804 2.13837 3.20056 2.71939 2.73574C3.31013 2.26315 4.03383 1.99907 4.99806 2H5H35C35.9671 2 36.6922 2.26505 37.2831 2.73774C37.8642 3.20261 38.0006 3.59842 38 3.99697V4V36C38 36.402 37.8616 36.7994 37.2806 37.2643C36.6899 37.7369 35.9662 38.0009 35.0019 38H35H5C4.03289 38 3.30776 37.735 2.71689 37.2623C2.13581 36.7974 1.9994 36.4016 2 36.003Z'
        fill='white'
        stroke={color}
        strokeWidth='4'
      />
      <path d='M23 19L28 15.7857L33 19V4H23V19Z' fill={color} />
    </svg>
  </Box>
)

Catalog.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Catalog
