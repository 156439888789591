import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type ClientsFillProps = {
  color?: string
} & BoxProps

const ClientsFill = ({ color, ...props }: ClientsFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.33333 0H36.6667C38.4167 0 40 1.9 40 4V32C40 34.1 38.4167 36 36.6667 36H3.33333C1.58333 36 0 34.1 0 32V4C0 1.9 1.58333 0 3.33333 0Z'
        fill={color}
      />
      <path
        d='M21 25V23.125C21 20.635 15.99 19.375 13.5 19.375C11.01 19.375 6 20.635 6 23.125V25H21ZM13.5 10C12.5054 10 11.5516 10.3951 10.8483 11.0983C10.1451 11.8016 9.75 12.7554 9.75 13.75C9.75 14.2425 9.847 14.7301 10.0355 15.1851C10.2239 15.64 10.5001 16.0534 10.8483 16.4017C11.5516 17.1049 12.5054 17.5 13.5 17.5C13.9925 17.5 14.4801 17.403 14.9351 17.2145C15.39 17.0261 15.8034 16.7499 16.1517 16.4017C16.4999 16.0534 16.7761 15.64 16.9646 15.1851C17.153 14.7301 17.25 14.2425 17.25 13.75C17.25 13.2575 17.153 12.7699 16.9646 12.3149C16.7761 11.86 16.4999 11.4466 16.1517 11.0983C15.8034 10.7501 15.39 10.4739 14.9351 10.2855C14.4801 10.097 13.9925 10 13.5 10Z'
        fill='#F9F5FF'
      />
      <path
        d='M21 25V23.125C21 20.635 15.99 19.375 13.5 19.375C11.01 19.375 6 20.635 6 23.125V25H21ZM13.5 10C12.5054 10 11.5516 10.3951 10.8483 11.0983C10.1451 11.8016 9.75 12.7554 9.75 13.75C9.75 14.2425 9.847 14.7301 10.0355 15.1851C10.2239 15.64 10.5001 16.0534 10.8483 16.4017C11.5516 17.1049 12.5054 17.5 13.5 17.5C13.9925 17.5 14.4801 17.403 14.9351 17.2145C15.39 17.0261 15.8034 16.7499 16.1517 16.4017C16.4999 16.0534 16.7761 15.64 16.9646 15.1851C17.153 14.7301 17.25 14.2425 17.25 13.75C17.25 13.2575 17.153 12.7699 16.9646 12.3149C16.7761 11.86 16.4999 11.4466 16.1517 11.0983C15.8034 10.7501 15.39 10.4739 14.9351 10.2855C14.4801 10.097 13.9925 10 13.5 10Z'
        stroke={color}
      />
      <rect x='22' y='10' width='12' height='2' rx='0.4' fill='#F9F5FF' />
      <rect x='22' y='15' width='12' height='2' rx='0.4' fill='#F9F5FF' />
      <rect x='24' y='20' width='10' height='2' rx='0.4' fill='#F9F5FF' />
    </svg>
  </Box>
)

ClientsFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default ClientsFill
