import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { PlacesType } from 'react-tooltip'

import { Box, Flex, FlexProps, Text } from '@nexpy/design-system'
import { useClientSide } from 'hooks/useClientSide'
import { ConsoleRoutes } from 'routes/console'
import { useMediaQuery } from 'usehooks-ts'

import Calendar from 'components/icons/Calendar'
import CalendarFill from 'components/icons/CalendarFill'
import Catalog from 'components/icons/Catalog'
import CatalogFill from 'components/icons/CatalogFill'
import Clients from 'components/icons/Clients'
import ClientsFill from 'components/icons/ClientsFill'
import Reports from 'components/icons/Reports'
import ReportsFill from 'components/icons/ReportsFill'
import Settings from 'components/icons/Settings'
import SettingsFill from 'components/icons/SettingsFill'

import { CompanyContext } from 'contexts/CompanyContext'

import { MOBILE_BREAKPOINT, TOPBAR_HEIGHT } from 'constants/layout'

import { customTheme } from 'theme/theme'

import TooltipButton from '../TooltipButton'

const MdShare = dynamic(() => import('react-icons/md').then(mod => mod.MdShare))
const MdError = dynamic(() => import('react-icons/md').then(mod => mod.MdError))

type ConsoleNavBarIconProps = {
  isActive: boolean
}

const CalendarIcon = ({ isActive }: ConsoleNavBarIconProps) => {
  if (isActive) {
    return <CalendarFill w='2.45rem' />
  }

  return <Calendar w='2.45rem' />
}

const ClientsIcon = ({ isActive }: ConsoleNavBarIconProps) => {
  if (isActive) {
    return <ClientsFill w='2.45rem' />
  }

  return <Clients w='2.45rem' />
}

const CatalogIcon = ({ isActive }: ConsoleNavBarIconProps) => {
  if (isActive) {
    return <CatalogFill w={{ _: '2.37rem', md: '2.43rem' }} />
  }

  return <Catalog w={{ _: '2.37rem', md: '2.43rem' }} />
}

const ReportsIcon = ({ isActive }: ConsoleNavBarIconProps) => {
  if (isActive) {
    return <ReportsFill w='2.45rem' />
  }

  return <Reports w='2.45rem' />
}

const SettingsIcon = ({ isActive }: ConsoleNavBarIconProps) => {
  if (isActive) {
    return <SettingsFill w='2.45rem' />
  }

  return <Settings w='2.45rem' />
}

const profileTooltipSettings = {
  place: 'right' as PlacesType,
  style: {
    marginLeft: '0.1rem',
  },
}

const tooltipSettings = {
  place: 'right' as PlacesType,
  style: {
    marginLeft: '1.2rem',
    zIndex: 30,
  },
}

const ConsoleNavBar = ({ ...props }: FlexProps) => {
  const currentCompany = CompanyContext.useSelector(state => state.currentCompany)

  const schedulingImpediments = CompanyContext.useSelector(
    state => state.schedulingImpediments
  )

  const isClient = useClientSide()
  const router = useRouter()

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  useEffect(() => {
    router.prefetch(ConsoleRoutes.PROFILE)
    router.prefetch(ConsoleRoutes.CALENDAR)
    router.prefetch(ConsoleRoutes.CLIENTS)
    router.prefetch(ConsoleRoutes.CATALOG)
    router.prefetch(ConsoleRoutes.REPORTS)
  }, [router])

  const handleGoToProfile = useCallback(() => {
    router.push(ConsoleRoutes.PROFILE)
  }, [router])

  const handleGoToCalendar = useCallback(() => {
    router.push(ConsoleRoutes.CALENDAR)
  }, [router])

  const handleGoToClients = useCallback(() => {
    router.push(ConsoleRoutes.CLIENTS)
  }, [router])

  const handleGoToCatalog = useCallback(() => {
    router.push(ConsoleRoutes.CATALOG)
  }, [router])

  const handleGoToReports = useCallback(() => {
    router.push(ConsoleRoutes.REPORTS)
  }, [router])

  const handleGoToShare = useCallback(() => {
    router.push(ConsoleRoutes.SHARE)
  }, [router])

  const handleGoToSettings = useCallback(() => {
    router.push(ConsoleRoutes.SETTINGS_GENERAL)
  }, [router])

  return (
    <>
      <Flex
        position='fixed'
        top={{ md: TOPBAR_HEIGHT }}
        bottom={0}
        left={0}
        right={{ _: 0, md: 'unset' }}
        zIndex={{ _: '2', md: 'unset' }}
        bg='white'
        w={{ md: '5.8rem' }}
        h={{ _: '6rem', md: 'unset' }}
        flexDirection={{ md: 'column' }}
        justifyContent={{ _: 'space-evenly', md: 'flex-start' }}
        p={{ md: '2.4rem 0' }}
        gap={{ md: '4.2rem' }}
        alignItems='center'
        borderRightColor={{ md: 'platinum' }}
        borderRightStyle={{ md: 'solid' }}
        borderRightWidth={{ md: '1px' }}
        borderTopColor={{ _: 'gainsboro', md: 'unset' }}
        borderTopStyle={{ _: 'solid', md: 'unset' }}
        borderTopWidth={{ _: '1px', md: 'unset' }}
        maxHeight={{
          md: `calc(100vh - (${TOPBAR_HEIGHT}))`,
        }}
        overflowY={{ md: 'auto' }}
        overflowX='hidden'
        className='small-scroll'
        {...props}
      >
        <Box
          position='relative'
          order={{
            _: {
              '&:nth-child(1)': '5',
            },
            md: {
              '&:nth-child(1)': '1',
            },
          }}
        >
          {(schedulingImpediments.hasImpediment ||
            currentCompany?.CompanyPayment?.PaymentStatus === 'OVERDUE') &&
          isClient ? (
            <Box
              position='absolute'
              top='-0.4rem'
              right='-0.4rem'
              zIndex='2'
              display={{ md: 'none' }}
            >
              <MdError color={customTheme.colors.danger} size='2rem' />
            </Box>
          ) : null}

          {currentCompany?.CompanyDetails?.LogoImage?.url ? (
            <>
              <TooltipButton
                p='2px'
                borderRadius='50%'
                onClick={handleGoToProfile}
                background={
                  router.pathname.startsWith(ConsoleRoutes.PROFILE)
                    ? 'linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                    : 'white'
                }
                label='Perfil da empresa'
                tooltipId='profile'
                h='fit-content'
                tooltip={profileTooltipSettings}
                display={{ '2xl': 'none' }}
                usePortal
                disableTooltip={isMobile}
              >
                <Flex
                  borderColor='white'
                  borderWidth='2px'
                  borderStyle='solid'
                  borderRadius='50%'
                >
                  <Image
                    src={currentCompany.CompanyDetails.LogoImage.url}
                    alt={currentCompany.CompanyDetails.LogoImage.alt}
                    width={32}
                    height={32}
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                </Flex>
              </TooltipButton>
              <TooltipButton
                p='2px'
                borderRadius='50%'
                onClick={handleGoToProfile}
                background={
                  router.pathname.startsWith(ConsoleRoutes.PROFILE)
                    ? 'linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                    : 'white'
                }
                label='Perfil da empresa'
                tooltipId='profile'
                h='fit-content'
                tooltip={profileTooltipSettings}
                display={{ _: 'none', '2xl': 'initial' }}
                usePortal
                disableTooltip={isMobile}
              >
                <Flex
                  borderColor='white'
                  borderWidth='2px'
                  borderStyle='solid'
                  borderRadius='50%'
                >
                  <Image
                    src={currentCompany.CompanyDetails.LogoImage.url}
                    alt={currentCompany.CompanyDetails.LogoImage.alt}
                    width={36}
                    height={36}
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                </Flex>
              </TooltipButton>
            </>
          ) : (
            <>
              <TooltipButton
                p='2px'
                borderRadius='50%'
                onClick={handleGoToProfile}
                background={
                  router.pathname.startsWith(ConsoleRoutes.PROFILE)
                    ? 'linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                    : customTheme.colors.oldSilver
                }
                label='Perfil da empresa'
                tooltipId='profile'
                h='fit-content'
                tooltip={tooltipSettings}
                display={{ '2xl': 'none' }}
                usePortal
                disableTooltip={isMobile}
              >
                <Flex
                  borderColor='white'
                  borderWidth='2px'
                  borderStyle='solid'
                  borderRadius='50%'
                >
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    w='32px'
                    h='32px'
                    borderRadius='50%'
                  >
                    {currentCompany?.name ? (
                      <Text color='white' variant='heading'>
                        {currentCompany.name?.trim?.()?.split?.('')?.[0] || ''}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
              </TooltipButton>
              <TooltipButton
                p='2px'
                borderRadius='50%'
                onClick={handleGoToProfile}
                background={
                  router.pathname.startsWith(ConsoleRoutes.PROFILE)
                    ? 'linear-gradient(136.97deg, #9855FF 15.48%, #5C50FF 88.07%)'
                    : customTheme.colors.oldSilver
                }
                label='Perfil da empresa'
                tooltipId='profile'
                h='fit-content'
                tooltip={tooltipSettings}
                display={{ _: 'none', '2xl': 'initial' }}
                usePortal
                disableTooltip={isMobile}
              >
                <Flex
                  borderColor='white'
                  borderWidth='2px'
                  borderStyle='solid'
                  borderRadius='50%'
                >
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    w='36px'
                    h='36px'
                    borderRadius='50%'
                  >
                    {currentCompany?.name ? (
                      <Text color='white' variant='heading'>
                        {currentCompany.name?.trim?.()?.split?.('')?.[0] || ''}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
              </TooltipButton>
            </>
          )}
        </Box>

        <TooltipButton
          label='Agenda'
          tooltipId='calendar'
          h='fit-content'
          onClick={handleGoToCalendar}
          tooltip={tooltipSettings}
          usePortal
          disableTooltip={isMobile}
          order={{
            _: {
              '&:nth-child(2)': '1',
            },
            md: {
              '&:nth-child(2)': '2',
            },
          }}
        >
          <CalendarIcon isActive={router.pathname.startsWith(ConsoleRoutes.CALENDAR)} />
        </TooltipButton>

        <TooltipButton
          label='Clientes'
          tooltipId='clients'
          h='fit-content'
          onClick={handleGoToClients}
          tooltip={tooltipSettings}
          usePortal
          disableTooltip={isMobile}
          order={{
            _: {
              '&:nth-child(3)': '2',
            },
            md: {
              '&:nth-child(3)': '3',
            },
          }}
        >
          <ClientsIcon isActive={router.pathname.startsWith(ConsoleRoutes.CLIENTS)} />
        </TooltipButton>

        <TooltipButton
          label='Catálogo de serviços'
          tooltipId='catalog'
          h='fit-content'
          onClick={handleGoToCatalog}
          tooltip={tooltipSettings}
          usePortal
          disableTooltip={isMobile}
          order={{
            _: {
              '&:nth-child(4)': '3',
            },
            md: {
              '&:nth-child(4)': '4',
            },
          }}
        >
          <CatalogIcon isActive={router.pathname.startsWith(ConsoleRoutes.CATALOG)} />
        </TooltipButton>

        <TooltipButton
          label='Relatórios'
          tooltipId='reports'
          h='fit-content'
          onClick={handleGoToReports}
          tooltip={tooltipSettings}
          usePortal
          disableTooltip={isMobile}
          order={{
            _: {
              '&:nth-child(5)': '4',
            },
            md: {
              '&:nth-child(5)': '5',
            },
          }}
        >
          <ReportsIcon isActive={router.pathname.startsWith(ConsoleRoutes.REPORTS)} />
        </TooltipButton>

        <TooltipButton
          label='Configurações'
          tooltipId='settings-nav-bar'
          h='fit-content'
          onClick={handleGoToSettings}
          tooltip={tooltipSettings}
          usePortal
          position='relative'
          display={{ _: 'none', md: 'flex' }}
          disableTooltip={isMobile}
          order={{
            '&:nth-child(6)': '6',
          }}
        >
          {(schedulingImpediments.hasImpediment ||
            currentCompany?.CompanyPayment?.PaymentStatus === 'OVERDUE') &&
          isClient ? (
            <Box position='absolute' top='-1rem' right='-0.8rem'>
              <MdError color={customTheme.colors.danger} size='2rem' />
            </Box>
          ) : null}
          <SettingsIcon isActive={router.pathname.startsWith(ConsoleRoutes.SETTINGS)} />
        </TooltipButton>

        <Box
          h='1.6rem'
          minHeight='1.6rem'
          bg='transparent'
          display={{ _: 'none', md: 'block' }}
          order={{ '&:nth-child(7)': '7' }}
        />

        <TooltipButton
          label='Compartilhar'
          tooltipId='share-nav-bar'
          h='fit-content'
          onClick={handleGoToShare}
          tooltip={tooltipSettings}
          usePortal
          position='fixed'
          bottom='2.4rem'
          bg='white'
          zIndex='10'
          display={{ _: 'none', md: 'flex' }}
          order={{ '&:nth-child(8)': '8' }}
          disableTooltip={isMobile}
        >
          <MdShare size='2.45rem' color={customTheme.colors.dodger} />
        </TooltipButton>

        <Box
          position='fixed'
          bg='white'
          bottom='0'
          h='5rem'
          minHeight='3rem'
          w='5rem'
          display={{ _: 'none', md: 'block' }}
          order={{ '&:nth-child(9)': '9' }}
        />
      </Flex>

      <Box w='5.8rem' minWidth='5.8rem' display={{ _: 'none', md: 'block' }} />
    </>
  )
}

export default ConsoleNavBar
