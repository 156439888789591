/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */

import { Component, ReactNode } from 'react'

import { captureException } from 'utils/sentry'

type ErrorBoundaryProps = {
  feedbackUi: ReactNode | null
  errorBoundaryId: string
  useCapture?: boolean
  children: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    useCapture: true,
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    const { errorBoundaryId } = this.props

    try {
      if (this.props.useCapture) {
        captureException(
          new Error(
            `[ErrorBoundary Error]
              error: ${error} ${JSON.stringify(error, null, 2)}
              errorInfo: ${errorInfo} ${JSON.stringify(errorInfo, null, 2)}
              errorId: ${errorBoundaryId}
          `
          )
        )

        return
      }

      console.error(error, errorInfo)
    } catch (captureError) {
      console.error(captureError)
    }
  }

  render() {
    const { hasError } = this.state
    const { children, feedbackUi } = this.props

    if (hasError && feedbackUi) {
      return feedbackUi
    }

    return children
  }
}

export default ErrorBoundary
