import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type ReportsProps = {
  color?: string
} & BoxProps

const Reports = ({ color, ...props }: ReportsProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_408_792)'>
        <path
          d='M11.5 28.5V17.875M20 28.5V11.5M28.5 28.5V24.25M32.75 3H7.25C6.12283 3 5.04183 3.44777 4.2448 4.2448C3.44777 5.04183 3 6.12283 3 7.25V32.75C3 33.8772 3.44777 34.9582 4.2448 35.7552C5.04183 36.5522 6.12283 37 7.25 37H32.75C33.8772 37 34.9582 36.5522 35.7552 35.7552C36.5522 34.9582 37 33.8772 37 32.75V7.25C37 6.12283 36.5522 5.04183 35.7552 4.2448C34.9582 3.44777 33.8772 3 32.75 3Z'
          stroke={color}
          strokeWidth='4.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_408_792'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </Box>
)

Reports.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Reports
