import { ReactNode } from 'react'

import { Box } from '@nexpy/design-system'

import MobileNavBar from 'components/generics/MobileNavBar'
import TopBar from 'components/generics/TopBar'

import { MainRefContextContext } from 'contexts/MainRefContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

type DefaultTopBarWithoutFooterProps = {
  children: ReactNode
}

const DefaultTopBarWithoutFooter = ({ children }: DefaultTopBarWithoutFooterProps) => {
  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  return (
    <>
      <TopBar />

      <Box h={TOPBAR_HEIGHT} w='100%' />

      <Box
        ref={mainRef}
        as='main'
        p={{ _: '2.4rem', md: '3rem' }}
        maxHeight={`calc(100vh - ${TOPBAR_HEIGHT})`}
        overflowY='auto'
        w='100%'
      >
        {children}

        <MobileNavBar />
      </Box>
    </>
  )
}

export default DefaultTopBarWithoutFooter
