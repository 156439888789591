import { Company } from 'models/companies'
import { EmailAddress } from 'models/email-address'
import { PhoneNumber } from 'models/phone-number'
import { Schedule } from 'models/schedules'

export const UserRole = {
  DEFAULT: 'DEFAULT',
  ADMIN: 'ADMIN',
} as const

export type InternalUser = {
  id: string
  createdAt: string
  updatedAt: string
  integrationId: string
  username: string
  firstName: string
  lastName: string
  primaryPhoneNumberIntegrationId: string
  primaryEmailIntegrationId: string
  profileImageUrl: string | null
  role: keyof typeof UserRole
  PhoneNumbers: PhoneNumber[]
  EmailAddresses: EmailAddress[]
  Companies: Company[]
  Schedule?: Omit<Schedule, 'CompanyService' | 'Employee' | 'Client' | 'Company'>[]
}
