import dynamic from 'next/dynamic'
import { useCallback } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Box, Button, Text, X } from '@nexpy/design-system'
import { NEXT_PUBLIC_DEVELOPMENT_FEATURES } from 'environment'
import { motion } from 'framer-motion'
import { UserRole } from 'models/internal-user'
import { AccountManageRoutes } from 'routes/account-manage'

import MenuItem from 'components/generics/MenuItem'

import { AccountManageMenuContext } from 'contexts/AccountManageMenuContext'
import { InternalUserContext } from 'contexts/InternalUserContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

import styles from './AccountManageMenu.module.scss'
import { Container, ContainerBackground } from './styles'

const UserFill = dynamic(() => import('components/icons/UserFill'))

const FiX = dynamic(() => import('react-icons/fi').then(mod => mod.FiX))

const AiFillBug = dynamic(() => import('react-icons/ai').then(mod => mod.AiFillBug))
// const AiFillClockCircle = dynamic(() =>
//   import('react-icons/ai').then(mod => mod.AiFillClockCircle)
// )
// const AiFillInfoCircle = dynamic(() =>
//   import('react-icons/ai').then(mod => mod.AiFillInfoCircle)
// )

const AiOutlineBug = dynamic(() => import('react-icons/ai').then(mod => mod.AiOutlineBug))
// const AiOutlineClockCircle = dynamic(() =>
//   import('react-icons/ai').then(mod => mod.AiOutlineClockCircle)
// )
// const AiOutlineInfoCircle = dynamic(() =>
//   import('react-icons/ai').then(mod => mod.AiOutlineInfoCircle)
// )
const AiOutlineUser = dynamic(() =>
  import('react-icons/ai').then(mod => mod.AiOutlineUser)
)

// const MdOutlinePalette = dynamic(() =>
//   import('react-icons/md').then(mod => mod.MdOutlinePalette)
// )
// const MdPalette = dynamic(() => import('react-icons/md').then(mod => mod.MdPalette))
const MdEmail = dynamic(() => import('react-icons/md').then(mod => mod.MdEmail))
const MdOutlineEmail = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineEmail)
)

type UserIconProps = {
  isActive: boolean
}

const UserIcon = ({ isActive }: UserIconProps) => {
  if (isActive) {
    return <UserFill w='2.4rem' />
  }

  return <AiOutlineUser size='2.4rem' />
}

// const PaletteIcon = ({ isActive }: UserIconProps) => {
//   if (isActive) {
//     return <MdPalette size='2.4rem' />
//   }

//   return <MdOutlinePalette size='2.4rem' />
// }

// const ClockIcon = ({ isActive }: UserIconProps) => {
//   if (isActive) {
//     return <AiFillClockCircle size='2.4rem' />
//   }

//   return <AiOutlineClockCircle size='2.4rem' />
// }

const BellIcon = ({ isActive }: UserIconProps) => {
  if (isActive) {
    return <MdEmail size='2.4rem' />
  }

  return <MdOutlineEmail size='2.4rem' />
}

// const InfoIcon = ({ isActive }: UserIconProps) => {
//   if (isActive) {
//     return <AiFillInfoCircle size='2.4rem' />
//   }

//   return <AiOutlineInfoCircle size='2.4rem' />
// }

const BugIcon = ({ isActive }: UserIconProps) => {
  if (isActive) {
    return <AiFillBug size='2.4rem' />
  }

  return <AiOutlineBug size='2.4rem' />
}

const containerBackgroundMotionVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  visible: {
    opacity: 0.6,
    display: 'block',
  },
}

const AccountManageMenu = () => {
  const { isOpened, setIsOpened } = AccountManageMenuContext.useContext()

  const currentUser = InternalUserContext.useSelector(state => state.currentUser)

  const handleCloseMenu = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const swipeBackgroundCloseHandlers = useSwipeable({
    onSwipedRight: () => setIsOpened(false),
  })

  const swipeMenuCloseHandlers = useSwipeable({
    onSwipedRight: () => setIsOpened(false),
  })

  const routeChangeStart = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  return (
    <>
      <motion.div
        initial='hidden'
        animate={isOpened ? 'visible' : 'hidden'}
        variants={containerBackgroundMotionVariants}
        className={styles.motionBackground}
      >
        <ContainerBackground
          display={{ _: 'initial', md: 'none' }}
          onClick={handleCloseMenu}
          {...swipeBackgroundCloseHandlers}
        />
      </motion.div>

      <Container
        {...swipeMenuCloseHandlers}
        right={{ _: isOpened ? '0' : '-30rem !important', md: 'unset !important' }}
        left={{ md: '0' }}
        boxShadow={{
          _: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
          md: 'none',
        }}
        top={{ _: '0', md: TOPBAR_HEIGHT }}
        bottom={{ _: '0', md: '0' }}
        borderRightColor={{ md: 'platinum' }}
        borderRightStyle={{ md: 'solid' }}
        borderRightWidth={{ md: '1px' }}
        borderRadius={{ md: '0' }}
        zIndex={{ _: '10', md: '5' }}
        as='nav'
      >
        <Box position='absolute' right='1.2rem' top='1rem' display={{ md: 'none' }}>
          <Button variant='ghost' aria-label='Fechar menu' onClick={handleCloseMenu}>
            <FiX size='2rem' />
          </Button>
        </Box>
        <Text fontWeight='600' variant='subheading'>
          Gerenciar Conta
        </Text>

        <X.ul
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap='2.4rem'
          w='100%'
        >
          <X.li w='100%'>
            <MenuItem
              label='Conta e segurança'
              path={AccountManageRoutes.PROFILE}
              icon={UserIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li>
          {/* <X.li w='100%'>
            <MenuItem
              label='Personalização'
              path={AccountManageRoutes.PERSONALIZATION}
              icon={PaletteIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li> */}
          {/* <X.li w='100%'>
            <MenuItem
              label='Sua atividade'
              path={AccountManageRoutes.ACTIVITY}
              icon={ClockIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li> */}
          <X.li w='100%'>
            <MenuItem
              label='Notificações'
              path={AccountManageRoutes.NOTIFICATIONS}
              icon={BellIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li>
          {/* <X.li w='100%'>
            <MenuItem
              label='Privacidade'
              path={AccountManageRoutes.PRIVACY}
              icon={InfoIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li> */}
          {/* <X.li w='100%'>
            <MenuItem
              label='Relatar um problema'
              path={AccountManageRoutes.REPORT_PROBLEM}
              icon={BugIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li> */}

          {NEXT_PUBLIC_DEVELOPMENT_FEATURES || currentUser?.role === UserRole.ADMIN ? (
            <X.li w='100%'>
              <MenuItem
                label='Dev'
                path={AccountManageRoutes.DEV}
                icon={BugIcon}
                events={{
                  routeChangeStart,
                }}
              />
            </X.li>
          ) : null}
        </X.ul>
      </Container>

      <Box display={{ _: 'none', md: 'initial' }} w='30rem' minWidth='30rem' />
    </>
  )
}

export default AccountManageMenu
