/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */

import { captureException as sentryCaptureException, flush } from '@sentry/nextjs'
import { NODE_ENV } from 'environment'

import { DefaultError } from 'types/exceptions'

export const captureException = async (error: DefaultError) => {
  if (NODE_ENV === 'production') {
    try {
      sentryCaptureException(error)

      const success = await flush(5000)

      if (!success) {
        throw new Error(
          `Flush pass: false. It may not have been possible to send exception data to our servers. Please report this bug! ${String(
            error
          )}`
        )
      }

      console.error(
        `Flush pass: ${success}`,
        'This exception was caught automatically and will be debugged.',
        'Your correction will be evaluated and contact support is not necessary.\n',
        error
      )
    } catch {
      console.error(
        `[MONITORING] It was not possible to send exception data for our servers. Please report this bug!
`,
        error
      )
    }
  } else {
    console.error('[DEV_ERROR] ', error)
  }
}
