import dynamic from 'next/dynamic'
import { useCallback } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Box, Button, Text, X } from '@nexpy/design-system'
import { motion } from 'framer-motion'
import { useClientSide } from 'hooks/useClientSide'
import { ConsoleRoutes } from 'routes/console'

import MenuItem from 'components/generics/MenuItem'

import { CompanyContext } from 'contexts/CompanyContext'
import { ConsoleSettingsMenuContext } from 'contexts/ConsoleSettingsMenuContext'

import { customTheme } from 'theme/theme'

import styles from './Menu.module.scss'
import { Container, ContainerBackground } from './styles'

const Settings = dynamic(() => import('components/icons/Settings'))
const SettingsFill = dynamic(() => import('components/icons/SettingsFill'))

const Employees = dynamic(() => import('react-icons/md').then(mod => mod.MdWorkOutline))
const EmployeesFill = dynamic(() => import('react-icons/md').then(mod => mod.MdWork))

const BusinessHours = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineAccessTime)
)
const BusinessHoursFill = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineAccessTimeFilled)
)

const MdWorkspacePremium = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdWorkspacePremium)
)
const MdOutlineWorkspacePremium = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineWorkspacePremium)
)

const FiX = dynamic(() => import('react-icons/fi').then(mod => mod.FiX))
const MdError = dynamic(() => import('react-icons/md').then(mod => mod.MdError))

type NavBarIconProps = {
  isActive: boolean
}

const SettingsIcon = ({ isActive }: NavBarIconProps) => {
  if (isActive) {
    return <SettingsFill w='2.2rem' color={customTheme.colors.black} />
  }

  return <Settings w='2.2rem' color={customTheme.colors.black} />
}

const EmployeesIcon = ({ isActive }: NavBarIconProps) => {
  if (isActive) {
    return <EmployeesFill size='2.2rem' color={customTheme.colors.black} />
  }

  return <Employees size='2.2rem' color={customTheme.colors.black} />
}

const BusinessHoursIcon = ({ isActive }: NavBarIconProps) => {
  if (isActive) {
    return <BusinessHoursFill size='2.2rem' color={customTheme.colors.black} />
  }

  return <BusinessHours size='2.2rem' color={customTheme.colors.black} />
}

const WorkspacePremium = ({ isActive }: NavBarIconProps) => {
  if (isActive) {
    return <MdWorkspacePremium size='2.5rem' color={customTheme.colors.black} />
  }

  return <MdOutlineWorkspacePremium size='2.5rem' color={customTheme.colors.black} />
}

const containerBackgroundMotionVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  visible: {
    opacity: 0.6,
    display: 'block',
  },
}

const Menu = () => {
  const { isOpened, setIsOpened } = ConsoleSettingsMenuContext.useContext()

  const isClient = useClientSide()

  const currentCompany = CompanyContext.useSelector(state => state.currentCompany)

  const handleCloseMenu = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const swipeBackgroundCloseHandlers = useSwipeable({
    onSwipedRight: () => setIsOpened(false),
  })

  const swipeMenuCloseHandlers = useSwipeable({
    onSwipedRight: () => setIsOpened(false),
  })

  const routeChangeStart = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  return (
    <>
      <motion.div
        initial='hidden'
        animate={isOpened ? 'visible' : 'hidden'}
        variants={containerBackgroundMotionVariants}
        className={styles.motionBackground}
      >
        <ContainerBackground
          display={{ _: 'initial', md: 'none' }}
          onClick={handleCloseMenu}
          {...swipeBackgroundCloseHandlers}
        />
      </motion.div>

      <Container
        {...swipeMenuCloseHandlers}
        right={{ _: isOpened ? '0' : '-30rem !important', md: 'unset' }}
        as='nav'
      >
        <Box position='absolute' right='1.2rem' top='1rem' display={{ md: 'none' }}>
          <Button variant='ghost' aria-label='Fechar menu' onClick={handleCloseMenu}>
            <FiX size='2rem' />
          </Button>
        </Box>
        <Text fontWeight='600' variant='subheading'>
          Configurações
        </Text>

        <X.ul
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap='2.4rem'
          w='100%'
        >
          <X.li w='100%'>
            <MenuItem
              label='Geral'
              path={ConsoleRoutes.SETTINGS_GENERAL}
              icon={SettingsIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li>
          <X.li w='100%'>
            <MenuItem
              label='Funcionários'
              path={ConsoleRoutes.SETTINGS_EMPLOYEES}
              icon={EmployeesIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li>
          <X.li w='100%'>
            <MenuItem
              label='Expediente'
              path={ConsoleRoutes.SETTINGS_BUSINESS_HOURS}
              icon={BusinessHoursIcon}
              events={{
                routeChangeStart,
              }}
            />
          </X.li>
          <X.li
            w='100%'
            position='relative'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <MenuItem
              label='Assinatura'
              path={ConsoleRoutes.SETTINGS_SUBSCRIPTION}
              icon={WorkspacePremium}
              events={{
                routeChangeStart,
              }}
            />

            {isClient && currentCompany?.CompanyPayment?.PaymentStatus === 'OVERDUE' ? (
              <Box>
                <MdError color={customTheme.colors.danger} size='2rem' />
              </Box>
            ) : null}
          </X.li>
        </X.ul>
      </Container>

      <Box display={{ _: 'none', md: 'initial' }} w='30rem' minWidth='30rem' />
    </>
  )
}

export default Menu
