import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

import { Box, Flex, InfiniteBarLoader, Portal } from '@nexpy/design-system'
import { AnimatePresence, motion } from 'framer-motion'

const SimpleLogo = dynamic(() => import('components/icons/SimpleLogo'))

type FullPageLoaderProps = {
  auth: boolean | undefined
  canRender: boolean
  children: ReactNode
}

const AuthFullPageLoader = ({ auth, canRender, children }: FullPageLoaderProps) => (
  <>
    <Portal>
      <AnimatePresence>
        {auth && !canRender ? (
          <motion.div
            style={{
              position: 'fixed',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              zIndex: '20',
            }}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key='AuthFullPageLoader'
          >
            <Flex variant='centerScreen' bg='white'>
              <Flex flexDirection='column' alignItems='center' gap='2.4rem'>
                <SimpleLogo w='7rem' />
                <InfiniteBarLoader isVisible />
              </Flex>
            </Flex>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Portal>

    <Box opacity={auth && !canRender ? '0' : '1'}>{children}</Box>
  </>
)
export default AuthFullPageLoader
