import type { ReactNode } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Box, Flex } from '@nexpy/design-system'

import AccountManageMenu from 'components/generics/AccountManageMenu'
import Footer from 'components/generics/Footer'
import ManageAccountButton from 'components/generics/ManageAccountButton'
import MobileNavBar from 'components/generics/MobileNavBar'
import TopBar from 'components/generics/TopBar'

import { AccountManageMenuContext } from 'contexts/AccountManageMenuContext'
import { MainRefContextContext } from 'contexts/MainRefContext'

import { TOPBAR_HEIGHT } from 'constants/layout'

type AccountManageProps = {
  children: ReactNode
}

const AccountManage = ({ children }: AccountManageProps) => {
  const setIsOpened = AccountManageMenuContext.useSelector(state => state.setIsOpened)

  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  const swipeMenuOpenHandlers = useSwipeable({
    onSwipedLeft: () => setIsOpened(true),
    delta: 13,
  })

  return (
    <Box {...swipeMenuOpenHandlers}>
      <TopBar />

      <Box h={TOPBAR_HEIGHT} w='100%' />

      <Flex justifyContent='space-between'>
        <AccountManageMenu />

        <Box maxHeight={`calc(100vh - ${TOPBAR_HEIGHT})`} overflowY='auto' w='100%'>
          <Box
            ref={mainRef}
            as='main'
            m={{ _: '2.4rem', md: '3rem' }}
            minHeight={`calc((100vh - 6rem) - ${TOPBAR_HEIGHT})`}
          >
            <ManageAccountButton />

            <Box mt={{ _: '-4.6rem', sm: '-4.1rem', md: '0' }}>
              {children}

              <MobileNavBar />

              <Footer />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default AccountManage
