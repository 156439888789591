import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Box, Flex, InfiniteBarLoader, Portal } from '@nexpy/design-system'
import { AnimatePresence, motion } from 'framer-motion'
import ms from 'ms'
import { HomeRoutes } from 'routes/home'

import { CompanyContext } from 'contexts/CompanyContext'

const SimpleLogo = dynamic(() => import('components/icons/SimpleLogo'))

type CompanyFullPageLoaderProps = {
  children: ReactNode
}

const CompanyFullPageLoader = ({ children }: CompanyFullPageLoaderProps) => {
  const userCompanies = CompanyContext.useSelector(state => state.userCompanies)

  const router = useRouter()

  useEffect(() => {
    if (userCompanies?.length) {
      return () => {}
    }

    const timer = setTimeout(() => {
      toast.error(
        'Não foi possível carregar o painel administrativo. Verifique sua conexão e tente novamente. Se o problema persistir, entre em contato com o suporte.',
        {
          autoClose: false,
        }
      )

      router.replace(HomeRoutes.ROOT)
    }, ms('20s'))

    return () => {
      clearTimeout(timer)
    }
  }, [router, userCompanies?.length])

  return (
    <>
      <Portal>
        <AnimatePresence>
          {!userCompanies?.length ? (
            <motion.div
              style={{
                position: 'fixed',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: '20',
              }}
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key='CompanyFullPageLoader'
            >
              <Flex variant='centerScreen' bg='white'>
                <Flex flexDirection='column' alignItems='center' gap='2.4rem'>
                  <SimpleLogo w='7rem' />
                  <InfiniteBarLoader isVisible />
                </Flex>
              </Flex>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Portal>

      <Box opacity={!userCompanies?.length ? '0' : '1'}>{children}</Box>
    </>
  )
}

export default CompanyFullPageLoader
