import type { ReactNode } from 'react'

import { Box } from '@nexpy/design-system'

import Footer from 'components/generics/Footer'

import { MainRefContextContext } from 'contexts/MainRefContext'

type AccountManageProps = {
  children: ReactNode
}

const Default = ({ children }: AccountManageProps) => {
  const mainRef = MainRefContextContext.useSelector(state => state.mainRef)

  return (
    <Box ref={mainRef} as='main' p={{ _: '2.4rem', md: '3rem' }}>
      {children}

      <Footer />
    </Box>
  )
}

export default Default
